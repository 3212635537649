import { CSSProperties } from 'react';
import './Loader.css';

const SmallLoader = () => {
    return (
        <div className="lds-ring">
            <div>
            </div>
            <div>
            </div>
            <div>
            </div>
            <div>
            </div>
        </div>
    )
}

export default SmallLoader;