// src/addresses.ts
var getAddresses = (chainId) => {
  switch (chainId) {
    case "archway-1":
      return {
        CREDIT_ADDRESS: "archway1dex9s7j726jd3an9vk0g00s9cm608jtznzjueczuzcvs2t9zralqhg4f5h",
        MARKETPLACE_ADDRESS: "archway1vg04jejq07cxulffmmgjtxs0gfsezx99a2ajtwuakrc8krf354rqgv8t4a",
        NFT_FACTORY_ADDRESS: "archway1974a8z4w0ph48nfexf5jt2nqxvmyzz3n9gahfv62d6yalr9fsm3qhln996",
        CW721_CODE_ID: 44,
        ARCHID_ADDRESS: "archway1275jwjpktae4y4y0cdq274a2m0jnpekhttnfuljm6n59wnpyd62qppqxq0",
        DISABLED_FEATURES: [],
        NOIS_PROXY: "archway10f3aasgsnpv84ymjyld50jayc4ecu267sdpjamauwm8nvxlzex9qj4dkwr",
        NOIS_PAYMENT_CONTRACT: "nois1rf3qhpwrs6zy0hun9qua56har9jrfau97c006czg7mjmhq7c09uqa2l7rg",
        RANDOMNESS_COST: 1
      };
      break;
    case "constantine-3":
      return {
        CREDIT_ADDRESS: "archway1xmlcasxa8xs9saz2avk9t4pcjyf6h94skhwdhm6vsqrsvhmvq96qnxvhku",
        MARKETPLACE_ADDRESS: "archway13f8fv36qm87kaj3hfl9fspyv2p6jusvu92z59z3f0vgs975xjy4qwdg4cg",
        NFT_FACTORY_ADDRESS: "archway14g4uv5lzmln30jqg8wutz396gqhdtf2v3vuf0yfarlaqxaqzuy7qy42plc",
        CW721_CODE_ID: 219,
        ARCHID_ADDRESS: "archway1lr8rstt40s697hqpedv2nvt27f4cuccqwvly9gnvuszxmcevrlns60xw4r",
        DISABLED_FEATURES: [],
        NOIS_PROXY: "archway1agttusk2xyffuuq2f5cefkatavrv2ywt8005pf3w05pcfsy2kuyqf93vqk",
        NOIS_PAYMENT_CONTRACT: "nois1lzswfaf7v4wyypfrnc0czsdcm4u0mavpfffx3d9uhqwgt84pq3wqm8e508",
        RANDOMNESS_COST: 1
      };
      break;
    case "localnet":
      return {
        CREDIT_ADDRESS: "archway149alhnfurwl2lmuxzvgve2e2ruqntvmkkml5y0d88v8rzym0edvqmjtxa8",
        MARKETPLACE_ADDRESS: "archway1887lhu29pqq68cm7kp9zrusxyg4zk0c79wty584k2upv6nek5z3s72zd3t",
        NFT_FACTORY_ADDRESS: "archway18q6pjutl59j5s2ex080hmak2kct0flwl5k9v7u2q6997hxta8dtqrk7vh7",
        CW721_CODE_ID: 638,
        ARCHID_ADDRESS: "",
        DISABLED_FEATURES: [],
        NOIS_PROXY: "archway1agttusk2xyffuuq2f5cefkatavrv2ywt8005pf3w05pcfsy2kuyqf93vqk",
        NOIS_PAYMENT_CONTRACT: "nois1lzswfaf7v4wyypfrnc0czsdcm4u0mavpfffx3d9uhqwgt84pq3wqm8e508",
        RANDOMNESS_COST: 1
      };
      break;
    default:
      throw `Unknown Chain ID ${chainId}`;
  }
};

// src/constants.ts
var PLACEHOLDER_URL = "https://placehold.jp/a2a2a2/ffffff/350x350.png?text=%F0%9F%93%B7";
var LOGIN_STRING = `Login to Architech`;
var ADMINS = [
  "archway1tmgvz9r9q0nlv00kclksvlk903pz9gjnaz62ng",
  "archway1z6efg4hw5eeaq50kjp55z7tehl2xxyg3nnny3x"
];
var getLoginString = (nonce) => {
  return `${LOGIN_STRING}
${nonce}`;
};
var CATEGORIES = [
  "Art",
  "PFPs",
  "Utility"
];
var DENOMS = [
  {
    nativeDenom: "aconst",
    decimals: 18,
    displayDenom: "CONST",
    image: "arch.svg",
    coingeckoId: "archway"
  },
  {
    nativeDenom: "aarch",
    decimals: 18,
    displayDenom: "ARCH",
    image: "arch.svg",
    coingeckoId: "archway"
  }
];
var unknownDenom = {
  decimals: 0,
  displayDenom: "UNKNOWN",
  image: "arch.svg"
};
var noDenom = {
  decimals: 0,
  displayDenom: "",
  image: ""
};
var findDenom = (denom) => {
  const found = DENOMS.find((d) => d.nativeDenom === denom);
  return found || unknownDenom;
};
var findToken = (cw20_addr) => {
  const found = DENOMS.find((d) => d.cw20Contract === cw20_addr);
  return found || unknownDenom;
};

// src/utils.ts
import BigNumber from "bignumber.js";
var resolveIpfs = (uri, useProxy = false) => {
  const isIpfs = uri.startsWith("ipfs://");
  if (isIpfs) {
    if (useProxy)
      uri = `${process.env.REACT_APP_BACKEND_URL}/ipfs/proxy/${uri.replace("ipfs://", "")}`;
    else
      uri = `https://ipfs.filebase.io/ipfs/${uri.replace("ipfs://", "")}`;
  }
  return uri;
};
var denomToHuman = (amount, decimals) => {
  amount = parseInt(amount.toString());
  const humanAmount = amount / Math.pow(10, decimals);
  return humanAmount;
};
var humanToDenom = (amount, decimals) => {
  amount = parseFloat(amount.toString());
  const result = amount * Math.pow(10, decimals);
  const denomAmount = BigNumber(result);
  return denomAmount.toFixed();
};
var calculateFee = (amount, fee) => {
  amount = parseFloat(amount.toString());
  const result = amount * fee;
  const feeAmount = BigNumber(result);
  return feeAmount.toFixed();
};
var findFloor = (asks, decimals) => {
  const floor = asks && asks.length ? asks.filter((a) => a.cw20_contract === void 0 || a.cw20_contract === null).sort((a, b) => parseInt(a.price) - parseInt(b.price))[0].price : "0";
  const floorAmount = denomToHuman(floor, decimals);
  return floorAmount;
};
var truncateAddress = (addr, prefix) => {
  if (prefix && !addr.startsWith(`${prefix}1`))
    return addr;
  return `${addr.slice(0, 10)}...${addr.slice(addr.length - 6)}`;
};
function randomString(length, chars = "0123456789abcdefghijklmnopqrstuvwxyz") {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
var epochToDate = (unixEpoch) => {
  if (typeof unixEpoch === "string")
    unixEpoch = parseInt(unixEpoch);
  return new Date(unixEpoch * 1e3);
};
var isCollectionCreator = (address, collection) => {
  return collection.creator === address || collection.admin === address;
};
var isAdmin = (address) => {
  return ADMINS.includes(address);
};
var trimNonNumeric = (str) => {
  return str.replace(/[^0-9.]/gi, "");
};
var canSeeCollection = (collection, userAddress) => {
  const isHidden = collection.hidden || collection.admin_hidden;
  const canSeeHidden = userAddress && (userAddress === collection.creator || userAddress === collection.admin || ADMINS.includes(userAddress));
  if (isHidden)
    return canSeeHidden;
  else
    return true;
};

// src/wasm/nftHandle.ts
var sendNft = async ({
  client,
  signer,
  contract,
  tokenId,
  recipient,
  subMsg
}) => {
  const msg = {
    send_nft: {
      contract: recipient,
      token_id: tokenId,
      msg: btoa(JSON.stringify(subMsg))
    }
  };
  const result = await client.execute(signer, contract, msg, "auto");
  return result;
};
var mintNft = async ({
  client,
  signer,
  contract,
  tokenId,
  extension,
  owner = signer
}) => {
  const msg = {
    mint: {
      owner,
      token_id: tokenId,
      extension
    }
  };
  const result = await client.execute(signer, contract, msg, "auto");
  return result;
};
var mintRoyaltyNft = async ({
  client,
  signer,
  contract,
  tokenId,
  owner = signer,
  royalty_payment_address,
  royalty_percentage
}) => {
  const msg = {
    mint: {
      owner,
      token_id: tokenId,
      extension: {
        royalty_payment_address,
        royalty_percentage
      }
    }
  };
  const result = await client.execute(signer, contract, msg, "auto");
  return result;
};
var transferNft = async ({
  client,
  signer,
  contract,
  tokenId,
  recipient
}) => {
  const msg = {
    transfer_nft: {
      token_id: tokenId,
      recipient
    }
  };
  const result = await client.execute(signer, contract, msg, "auto");
  return result;
};

// src/wasm/nftQuery.ts
var getContractInfo = async ({
  client,
  contract
}) => {
  const query = {
    contract_info: {}
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getNftInfo = async ({
  client,
  contract,
  token_id
}) => {
  const query = {
    nft_info: {
      token_id
    }
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getAllNftInfo = async ({
  client,
  contract,
  token_id,
  include_expired
}) => {
  const query = {
    all_nft_info: {
      token_id,
      include_expired
    }
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getRoyalty = async ({
  client,
  contract,
  token_id,
  sale_price
}) => {
  const extension_msg = {
    royalty_info: {
      sale_price,
      token_id
    }
  };
  const query = {
    extension: {
      msg: extension_msg
    }
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getOwnedTokens = async ({
  client,
  contract,
  owner,
  start_after,
  limit
}) => {
  const query = {
    tokens: {
      owner,
      start_after,
      limit
    }
  };
  const result = await client.queryContractSmart(contract, query);
  return result.tokens;
};
var getNumTokens = async ({ client, contract }) => {
  const query = {
    num_tokens: {}
  };
  const result = await client.queryContractSmart(contract, query);
  return result.count;
};
var getAllTokens = async ({
  client,
  contract,
  start_after,
  limit = 1e3
}) => {
  const query = {
    all_tokens: {
      limit,
      start_after
    }
  };
  const result = await client.queryContractSmart(contract, query);
  return result.tokens;
};

// src/wasm/cw20Handle.ts
var sendTokens = async ({
  client,
  signer,
  contract,
  amount,
  recipient,
  subMsg,
  funds = []
}) => {
  const msg = {
    send: {
      contract: recipient,
      amount,
      msg: Buffer.from(JSON.stringify(subMsg)).toString("base64")
    }
  };
  const result = await client.execute(signer, contract, msg, "auto");
  const handle = Object.keys(subMsg)[0];
  gasUsage[handle] ? gasUsage[handle].push(result.gasUsed) : gasUsage[handle] = [result.gasUsed];
  return result;
};
var transferTokens = async ({
  client,
  signer,
  contract,
  amount,
  recipient,
  funds = []
}) => {
  const msg = {
    transfer: {
      recipient,
      amount
    }
  };
  const result = await client.execute(signer, contract, msg, "auto", void 0);
  return result;
};

// src/wasm/cw20Query.ts
var getTokenInfo = async ({
  client,
  contract
}) => {
  const query = {
    token_info: {}
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getBalance = async ({
  client,
  contract,
  address
}) => {
  const query = {
    balance: {
      address
    }
  };
  const result = await client.queryContractSmart(contract, query);
  return result.balance;
};

// src/wasm/marketplaceHandle.ts
var purchaseNative = async ({
  client,
  signer,
  marketplace_contract,
  cw721_contract,
  token_id,
  denom,
  amount
}) => {
  const msg = {
    purchase_native: {
      collection: cw721_contract,
      token_id
    }
  };
  const result = await client.execute(signer, marketplace_contract, msg, "auto", void 0, [{ amount, denom }]);
  return result;
};
var purchaseCw20 = async ({
  client,
  signer,
  marketplace_contract,
  cw20_contract,
  cw721_contract,
  token_id,
  amount
}) => {
  const buyMsg = {
    purchase: {
      cw721_contract,
      token_id
    }
  };
  const buyResult = await sendTokens({
    client,
    signer,
    contract: cw20_contract,
    amount: amount.toString(),
    recipient: marketplace_contract,
    subMsg: buyMsg
  });
  return buyResult;
};
var listToken = async ({
  client,
  signer,
  marketplace_contract,
  cw20_contract,
  cw721_contract,
  token_id,
  amount
}) => {
  const listMsg = {
    set_listing: {
      cw20_contract,
      amount
    }
  };
  return await sendNft({
    client,
    signer,
    contract: cw721_contract,
    tokenId: token_id,
    recipient: marketplace_contract,
    subMsg: listMsg
  });
};
var cancelListing = async ({
  client,
  signer,
  marketplace_contract,
  cw721_contract,
  token_id
}) => {
  const msg = {
    remove_listing: {
      collection: cw721_contract,
      token_id
    }
  };
  return await client.execute(signer, marketplace_contract, msg, "auto", void 0);
};

// src/wasm/marketplaceQuery.ts
var getAllAsks = async ({
  client,
  contract,
  limit
}) => {
  const query = {
    get_all_asks: {
      limit
    }
  };
  const { asks } = await client.queryContractSmart(contract, query);
  return asks;
};
var getCollectionAsks = async ({
  client,
  contract,
  collection
}) => {
  const query = {
    get_collection_asks: {
      collection
    }
  };
  const { asks } = await client.queryContractSmart(contract, query);
  return asks;
};
var getSellerAsks = async ({
  client,
  contract,
  seller
}) => {
  const query = {
    get_seller_asks: {
      seller
    }
  };
  const { asks } = await client.queryContractSmart(contract, query);
  return asks;
};
var getAsk = async ({
  client,
  contract,
  collection,
  token_id
}) => {
  const query = {
    ask: {
      collection,
      token_id
    }
  };
  const { ask } = await client.queryContractSmart(contract, query);
  return ask;
};
var getVolume = async ({
  client,
  contract,
  collection
}) => {
  const query = {
    get_collection_volume: {
      collection
    }
  };
  const { volume } = await client.queryContractSmart(contract, query);
  return volume;
};
var getCollectionDossier = async ({
  client,
  contract,
  collection
}) => {
  const query = {
    collection_dossier: {
      collection
    }
  };
  const response = await client.queryContractSmart(contract, query);
  return response;
};
var getBatchCollectionDossier = async ({
  client,
  contract,
  collections
}) => {
  const query = {
    batch_collection_dossier: {
      collections
    }
  };
  const response = await client.queryContractSmart(contract, query);
  return response;
};

// src/wasm/creditQuery.ts
var getCreditBalance = async ({
  client,
  creditContract,
  address: user
}) => {
  const query = {
    check_balance: {
      user
    }
  };
  const result = await client.queryContractSmart(creditContract, query);
  return result.check_balance.amount;
};

// src/wasm/minterHandle.ts
var preloadData = async ({
  client,
  signer,
  contract,
  metadata
}) => {
  const msg = {
    preload_data: {
      new_data: metadata
    }
  };
  const result = await client.execute(signer, contract, msg, "auto");
  return result;
};
var mintWithMinter = async ({
  client,
  signer,
  minter_contract,
  funds
}) => {
  const msg = {
    mint: {}
  };
  const result = await client.execute(signer, minter_contract, msg, "auto", void 0, funds);
  console.log("Mint Gas Used", result.gasUsed);
  return result;
};
var setLaunchTime = async ({
  client,
  signer,
  minter_contract,
  launch_time,
  whitelist_launch_time
}) => {
  const instructions = [];
  if (launch_time) {
    const msg = {
      set_launch_time: {
        launch_time: Math.floor(launch_time.valueOf() / 1e3).toString()
      }
    };
    instructions.push({
      contractAddress: minter_contract,
      msg
    });
  }
  if (whitelist_launch_time) {
    const msg = {
      set_launch_time: {
        whitelist_launch_time: Math.floor(whitelist_launch_time.valueOf() / 1e3).toString()
      }
    };
    instructions.push({
      contractAddress: minter_contract,
      msg
    });
  }
  const result = await client.executeMultiple(signer, instructions, "auto");
  return result;
};

// src/wasm/minterQuery.ts
var getMintStatus = async ({
  client,
  contract
}) => {
  const query = {
    get_mint_status: {}
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getPrice = async ({
  client,
  contract
}) => {
  const query = {
    get_price: {}
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getConfig = async ({
  client,
  contract
}) => {
  const query = {
    get_config: {}
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};
var getMintLimit = async ({
  client,
  contract,
  buyer
}) => {
  const query = {
    get_mint_limit: {
      sender: buyer
    }
  };
  const result = await client.queryContractSmart(contract, query);
  return result;
};

// src/wasm/randomMinterHandle.ts
var mintRandom = async ({
  client,
  signer,
  minter_contract,
  funds,
  mints = 1
}) => {
  const msg = {
    mint: {
      mints
    }
  };
  const result = await client.execute(signer, minter_contract, msg, "auto", void 0, funds);
  console.log("Mint Gas Used", result.gasUsed);
  return result;
};

// src/wasm/archId.ts
var resolveArchId = async (client, contract, address) => {
  if (!address || !address.length || !contract || !contract.length)
    return;
  try {
    const query = {
      resolve_address: {
        address
      }
    };
    const { names } = await client.queryContractSmart(contract, query);
    if (names.length > 1)
      console.log("ARCHID Response has Multiple Names:", names);
    return names[0];
  } catch (err) {
    console.error("Error resolving ArchID:", err);
  }
};

// src/archway.ts
var setRewardsMetadata = async ({
  client,
  signer,
  contract,
  rewards_address
}) => {
  return await client.setContractMetadata(signer, {
    contractAddress: contract,
    rewardsAddress: rewards_address
  }, "auto");
};
var getMetadata = async ({
  client,
  contract
}) => {
  return await client.getContractMetadata(contract);
};
var getRewards = async ({
  client,
  address
}) => {
  try {
    return await client.getOutstandingRewards(address);
  } catch (err) {
    if (err.toString().includes("metadata for the contract: not found: key not found"))
      return void 0;
    else
      throw err;
  }
};
var claimRewards = async ({
  client,
  address,
  num_records
}) => {
  return await client.withdrawContractRewards(address, 100, "auto");
};

// src/errors/index.ts
import axios from "axios";

// src/errors/http.errors.ts
var HttpErrors = {
  400: "400: There was a problem with your request.",
  401: "401: You must be logged in to make this request.",
  403: "403: You are not allowed to access this resource.",
  404: "404: The requested resource was not found.",
  405: "405: Method not allowed.",
  409: "409: Request produced conflict response. Please report this to Architech.",
  413: "413: Uploaded content was too large.",
  415: "415: Uploaded file type is unsupported.",
  429: "429: Too many requests. Try again later.",
  500: "500: The server encountered an unknown error.",
  503: "503: Service temporarily unavailable.",
  504: "504: Request timed out."
};

// src/errors/index.ts
var parseError = (error) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      return error.response.data || HttpErrors[error.response.status];
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      return error.toString();
      console.log(error.request);
    } else {
      return error.message;
      console.log("Error", error.message);
    }
  } else {
    if (error.toString().includes("Collection has been minted out."))
      return "Collection has sold out.";
    if (error.toString().includes("decoding bech32 failed"))
      return "Invalid contract address.";
    else if (error.toString().includes("contract: not found"))
      return "Contract not found.";
    return error.toString();
  }
};
export {
  ADMINS,
  CATEGORIES,
  DENOMS,
  LOGIN_STRING,
  PLACEHOLDER_URL,
  calculateFee,
  canSeeCollection,
  cancelListing,
  claimRewards,
  denomToHuman,
  epochToDate,
  findDenom,
  findFloor,
  findToken,
  getAddresses,
  getAllAsks,
  getAllNftInfo,
  getAllTokens,
  getAsk,
  getBalance,
  getBatchCollectionDossier,
  getCollectionAsks,
  getCollectionDossier,
  getConfig,
  getContractInfo,
  getCreditBalance,
  getLoginString,
  getMetadata,
  getMintLimit,
  getMintStatus,
  getNftInfo,
  getNumTokens,
  getOwnedTokens,
  getPrice,
  getRewards,
  getRoyalty,
  getSellerAsks,
  getTokenInfo,
  getVolume,
  humanToDenom,
  isAdmin,
  isCollectionCreator,
  listToken,
  mintNft,
  mintRandom,
  mintRoyaltyNft,
  mintWithMinter,
  noDenom,
  parseError,
  preloadData,
  purchaseCw20,
  purchaseNative,
  randomString,
  resolveArchId,
  resolveIpfs,
  sendNft,
  sendTokens,
  setLaunchTime,
  setRewardsMetadata,
  transferNft,
  transferTokens,
  trimNonNumeric,
  truncateAddress,
  unknownDenom
};
